(function ($) {
    $(document).ready(function() {
        var today = new Date();

        var startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        var endDate = new Date();
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        endDate.setMilliseconds(00);

        $.ajax({
            url: "/api/events",
            data: {
                StartDate: [startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()].join('-'),
                EndDate: [endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()].join('-')
            },
            dataType: "json"
        }).done(function(data) {
            var whatsOnToday = '<ul class="whats-on-today-list">';

            if(data.length > 0) {
                $.each(data, function (index, event) {
                    var startDate = new Date(event.startDate);
                    startDate.setHours(event.startTime.hour);
                    startDate.setMinutes(event.startTime.minute);
                    startDate.setSeconds(event.startTime.second);
                    startDate.setMilliseconds(event.startTime.millisecond);
                    var startMoment = moment(startDate);
                    var time = event.allDayEvent ? 'All Day' : startMoment.format('h:mm');
                    var timeCode = event.allDayEvent ? '' : startMoment.format('A');
                    whatsOnToday = whatsOnToday + '<li><span class="time">' + time + '</span><span class="code">' + timeCode + '</span><a class="mx-2 font-weight-light text-white" style="text-decoration:underline;" href="/' + event.displayAlias + '">' + event.title + '</a></li>';
                });
            } else { 
                whatsOnToday = whatsOnToday + '<li class="font-weight-light text-white">No events today</li>'
            }

            whatsOnToday = whatsOnToday + '</ul>'


            $(whatsOnToday).appendTo($('#tc-whats-on-today'));
        });
	});
})(jQuery);
